@charset "utf-8";

/* modList
------------------------------------------------------------*/

.modListNormal { margin: 0 0 0 2em; }

ul.modListNormal li{
	&:before { content: "\0030fb"; margin-left:-2em; @include inline-block; width:2em; text-align:center;color:$mainColor;
    }
}
ol.modListNormal li { list-style:decimal; }

/* indent */
.modIndent01 li {
	text-indent: -1em;
	margin: 0 0 0 1em;
}

.modIndent02 li {
	text-indent: -2.5em;
	margin: 0 0 0 2.5em;
}

.modList01{
    padding-left: 70px;
    width: 70%;
    font-family: $fontMincho;
    @include tabletP{
        padding-left: 0;
        width: 100%;
    }
    li{
        margin-bottom: 60px;
        @include clearfix();
        @include fz(16);
        text-align: left;
        @include tabletP{
            margin-bottom: 20px;
            @include fz(14);
        }
        .tit{
            width: 20%;
            float: left;
            @include sphoneP{
                width: 100%;
                float:none;
                display: block;
            }
        }
        .txt{
            width: 80%;
            float: left;
            @include sphoneP{
                width: 100%;
                float:none;
            }
        }
    }
}

.modList02{
    font-family: $fontMincho;
    dt{
        color:#000;
        @include fz(28);
        position: relative;
        padding-left: 40px;
        margin-bottom: 15px;
        line-height: 1.4;
        @include tabletP{
            @include fz(23);
        }
        &:before{
            content:"";
            display: block;
            width: 54px;
            height: 49px;
            background-image: url(../img/common/decoSubTitle.png);
            background-repeat: no-repeat;
            background-position: left center;
            position: absolute;
            left:-25px;
            top:-10px;
            margin-top: -2px;
            @include tabletP{
                left:-20px;
                top:0;
                background-size: 100%;
            }
        }
    }
    dd{
        padding: 0 30px;
        @include fz(16);
        margin-bottom: 50px;
        @include tabletP{
            @include fz(14);
        }
        &:last-child{
            margin-bottom: 0;
        }
        
        
    }
}
/* フォーム
***************************************/
.modDlForm{
	margin:0 0 36px;
	
	dt{
		float:left;
		clear:both;
		width:16em;
		padding:33px 0 20px 63px;
		position:relative;
		
		@include tabletP{
			float:none;
			width:auto;
			padding:33px 0 0 63px;
		}
		&.required:before,
		&.any:before{
			position:absolute;
			left:0;
			top:33px;
			color:#FFF;
			@include fz(11);
			padding:2px 10px;
		}
		&.required:before{
			
			content:"必須";
			background:$colorStrong;
			
		}
		&.any:before{
			background:#999999;
			content:"任意";
		}
	}
	dd{
		border-bottom:solid 1px #ccc;
		padding:33px 0 ;
		padding-left:17em;
		
		@include tabletP{
			padding:10px 0;
		}
		.parts{
			padding:10px 0;
			textarea,
			input[type="text"],
			input[type="email"]
			{
				width:100%;
				
			}
			&.radio_inline,
			&.check_inline{
				>div{
					display:inline-block;
				}
			}
			&.name{
				display:flex;
				justify-content:space-between;
				>div{
					width:48.5%;
				}
				
			}
			&.post,&.tel{
				display:flex;
				.hyphen{
					padding:10px 10px 0 10px;
					
					@include sphoneP{
						padding:10px 5px 0 5px;
					}
				}
			}
			&.password{
				input{
					width:100%;
					max-width:300px;
					@include sphoneP{
						max-width:none;
					}
				}
				.text{
					padding:10px 0;
				}
			}
		}
	}
	
	.validationError{
		&:before{
			content: "\f071";
			@include iconFont;
			color:$colorStrong;
			margin-right: 6px;
		}
	}
}