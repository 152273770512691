@charset "utf-8";

// font
$baseFont: 14;
$baseLineHeight: 1.8;
$baseColor: #333;
$baseLinkColor: #333;
$baseSubColor: #ccc;
$mainColor: #e64398;
$mainColor02:#fef5fa;
$mainColor03:#c96a84;


// color
$colorStrong: #e4007f; //強調系（エラーなど）
$colorBlue: #3498DB;
$colorGreen: #1ABC9C;

// brake-point
$bpSphoneP: 600px;
$bpTabletP: 768px;
$bpTabletL: 992px;
$baseWidthWide: 1200px;

// container-size
$container-sm: 750px;
$container-md: 970px;
$container-lg: 1170px;

// container-padding
$containerPadding: 10px;

@font-face {
    font-family: "游明朝体";
    font-weight: normal;
    src: local("游明朝体"),  /* Postscript name */
        local("游明朝体"), /* for Chrome */
        local("游明朝体"); /* Mediumがない場合 */
}
@font-face {
    font-family: "Yu Mincho";
    font-weight: bold;
    src: local("Yu Mincho"), /* Postscript name */
        local("Yu Mincho");     /* for Chrome */
}

// font-family
$baseFontFamily : "メイリオ",Verdana, "Hiragino Kaku Gothic ProN", "游ゴシック", YuGothic, Meiryo, sans-serif;
$fontGothic : "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, Verdana, "游ゴシック", YuGothic, sans-serif;
$fontAlpha : Helvetica, Arial, "Hiragino Kaku Gothic ProN", "游ゴシック", YuGothic, Meiryo, sans-serif;
$fontMincho : "游明朝体", "Yu Mincho",'ヒラギノ明朝 Pro W3','Hiragino Mincho Pro',"HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝","メイリオ", Meiryo,  serif;


// icon webfont
$fa-angle-right : "\f105";
$fa-angle-left : "\f104";
$fa-angle-down:"\f107";
$fa-angle-up:"\f106";
$fa-home : "\f015";
$fa-phone : "\f095";
$fa-file-pdf-o : "\f1c1";
$fa-bars : "\f0c9";
$fa-times : "\f00d";
$fa-search : "\f002";
$fa-caret-right: "\f0da";
$fa-caret-down: "\f0d7";
$fa-caret-left: "\f0d9";
$fa-caret-up: "\f0d8";
$fa-chevron-down: "\f078";
$fa-chevron-up: "\f077";
$fa-plus: "\f067";
$fa-minus: "\f068";
$fa-chevron-right : "\f054";
$fa-chevron-left : "\f053";
$fa-book : "\f02d";
$fa-square  : "\f0c8";
$fa-twitter : "\f099";
$fa-facebook :"\f09a";
$fa-envelope :"\f0e0";
$fa-bars : "\f0c9";
$fa-remove:"\f00d";
$fa-pencil : "\f040";
$fa-check :"\f00c";
$fa-circle-o : "\f10c";
$fa-dot-circle-o : "\f192";
$fa-chevron-down : "\f078";
$fa-angle-right : "\f105";

