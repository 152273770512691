@charset "utf-8";

/* other
------------------------------------------------------------*/
/*↓消さないこと*/
.modLoading{
	position:fixed;
	top:0;
	bottom:0;
	left:0;
	right:0;
	background-color:rgba(255,255,255,0.8);
	
	i{
		@include fz(24);
		position:absolute;
		top:50%;
		left:50%;
		margin-left:-0.5em;
		margin-top:-0.5em;
	}
}
/*↑消さないこと*/

.modCaption {
    font-family: $fontMincho;
    @include fz(16);
    line-height: 2;
    color:#000;
    margin-bottom: 70px;
    @include tabletP{
        text-align: left;
        br{
            display: none;
            
        }
    }
    
}
.modAttention{
    font-family: $fontMincho;
    @include fz(14);
    line-height: 2;
    @include tabletP{
        text-align: left;
        br{
            display: none;

        }
    }

    &_white{
        color: #fff;
    }
    #first &{
        margin-bottom: 70px;
    }
}

.modLine01{
    margin-bottom: 20px;
}
.modBox01{
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 980px;
    .deco{
        position: absolute;
        @include tabletP {
            width: 30%;
        }
        &LT{
            left: -20px;
            top: -20px;
            @include tabletP {
                left: -15px;
                top: -15px;
            }
        }
        &LB{
            left: -20px;
            bottom: -20px;
            @include tabletP {
                left: -15px;
                bottom: -15px;
            }
        }
        &RT{
            right: -20px;
            top: -20px;
            @include tabletP {
                right: -15px;
                top: -15px;
            }
        }
        &RB{
            right: -20px;
            bottom: -20px;
            @include tabletP {
                right: -15px;
                bottom: -15px;
            }
        }
        
    }
}