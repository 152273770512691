
@charset "utf-8";

html{
	background-color: #000;
}
body{
	background-color: #fff;
}
/* layout */
#header {
	background-color: rgba(#000,0.5);
    @include sphoneP {
        @include clearfix(); 
    }
	.container{
		display: table;
		width: 100%;
        @include tabletP {
            display: block;
            padding: 0;
        }
	}
    .head{
        @include tabletP {
            sposition: relative;
            
        }
        .siteTitle{
            @include tabletP {
                width: 25%;
                float: left;
                padding-left: 2%;
            }
            @include sphoneP {
                margin-top: 5px;
                width: 35%; 
            }
        }
        #spNav{
            display: none;
            @include tabletP {
                display: block;
                padding-left: 140px;
            }
            
            ul{
                @include tabletP {
                    display: table;
                    table-layout: fixed;
                    width: 60%;
                    text-align: right;
                }
                li{
                    @include tabletP {
                        display: table-cell;
                        vertical-align: middle;
                        height:60px;
                    }
                    img{
                        @include tabletP {
                            width: auto;
                            height: 20px;
                        }
                    }
                }
            }
        }
        #menu{
            display: none;
            @include tabletP {
                display: block;
                position: absolute;
                top:0;
                right: 0;
                width: 56px;
            }
        }
        
    }
    .foot{
        @include tabletP {
            display: none;
            position: absolute;
            width: 100%;
        }
    }
	.siteTitle,.contact{
		display: table-cell;
		vertical-align: middle;
	}

	.siteTitle{
		padding: 10px 0;
        float: left;
        @include tabletP{
			width: 130px;
			padding: 8px 0;
		}
	}
	.btnNav{
		
	}
	.contact,.ec{
		float: right;
		text-align: right;
		padding: 20px 0;
		@media only screen and (max-width: 1200px){
			@include fz(10);
			
			span{
				display: block;
			}
		}
		@include tabletP{
			padding: 8px 0;
		}
		@include tabletP {
						display: none; 
		}
		a{
			display: inline-block;
			*display: inline;
			*zoom: 1;
			background-color: #000;
			padding: 10px 30px;
			color:#fff;
			font-family: $fontMincho;
			text-align: center;
			text-decoration: none;
			@media only screen and (max-width: 1200px){
				padding: 8px;
				height: 40px;
				line-height: 1.4;
			}
			@include tabletP{
				padding: 8px 10px;
				@include fz(10);
			}

			&:hover{
			}
		}

	}
	.contact{
		a{
			@media only screen and (max-width: 1200px){
				padding-top: 12px;
			}
			
		}
	}
	.ec{
		margin-right: 10px;
		
	}
    .close{
        display: none;
        @include tabletP {
            position: relative;
            z-index: 2000;
            display: block;
            background: #000;
            text-align: center;
            padding-top: 10px;
            padding-bottom: 20px;
        }
        span{
            @include tabletP {
                width: 80%;
                background: #111;
                border:1px solid #ccc;
                padding: 8px;
                border-radius: 3px;
                display: block;
                margin-left: auto;
                margin-right: auto;
                color:#fff;
            }
        }
    }

}

#gNav{
    float: left;
    margin-left: 30px;
    @include tabletP {
        background: #000;
        float:none;
        z-index: 2000;
        margin-left: 0;
        position: relative;
    }
    ul{
        display: table;
        table-layout: fixed;
        @include tabletP {
            display: block;
            width: 100%;
            
        }
    }
    li{
        display: table-cell;
        vertical-align: middle;
        font-family: $fontMincho;
        @include tabletP {
            display: block;
            width: 100%;
        }
        a{
            color:#fff;
            padding: 30px 10px;
            display: block;
            position: relative;
            @include tabletP {
                padding: 15px; 
                text-align: center;
                border-bottom:1px solid #1f1f1f;
            }
            &:hover{
                text-decoration: none;
                &:after{
                    content:"";
                    display: block;
                    width: 100%;
                    height: 1px;
                    background-color: $mainColor;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    @include sphoneP {
                    	display: none; 
                    }
                }
            }
        }
					&.spOnly{
						display: none;
						@include tabletP {
							display: inline-block;
						}
					}
    }
}
#top .navHome,
#first .navFirst,
#course .navCourse,
#staff .navStaff,
#shop .navShop,
#company .navCompany{
    a:after{
        content:"";
        display: block;
        width: 100%;
        height: 1px;
        background-color: $mainColor;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        @include sphoneP {
            display: none; 
        }
    }
}
#top{
}

#contents {
    
	.mainVisual{
		background-image: url(../img/top/bgMainVisual.jpg);
		background-position: center top;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
		height: 760px;
		@include tabletP{
			background-image: url(../img/top/bgMainVisual@2x.jpg);
			height: 120vw;
		}

		.container{
		}

		.logo{
			position: absolute;
			bottom:40px;
			@include tabletP{
				bottom:5vw;
			}
		}
	}

	.menuBlock{
        background-image: url(../img/common/bg01.jpg);
		background-position: center top;
		padding: 60px 0;
		@include tabletP{
			padding: 30px 0;
		}

		.title{
			margin-bottom: 50px;
			text-align: center;
			@include tabletP{
				margin-bottom: 30px;
				img{
					width: 100px;
				}
			}
		}

		.menu{
			@include clearfix;
			max-width: 980px;
			margin:0 auto 60px;
            @include tabletP{
				padding: 0 30px;
                margin-bottom: 30px;
			}

			li{
				float: left;
				width: 49%;
				box-shadow: rgba(0,0,0,0.2) 0 5px 20px;
				@include tabletP{
					float: none;
					width: auto;
					text-align: center;
				}
				&:last-child{
					float: right;
					@include tabletP{
						float: none;
						margin-top: 30px;
					}
				}
			}
		}
        .btn{
            max-width: 330px;
            margin: 0 auto;
            @include tabletP{
                padding: 0 20px;
            }
        }
	}

	.contactBlock{
        background-image: url(../img/common/bg02.jpg);
		background-size: cover;
		background-position: center;
		padding: 80px 0;
		text-align: center;
		@include tabletP{
			padding: 30px 10px;
		}

		.body{
			background-color: #000;
			max-width: 550px;
			margin:0 auto;
			padding: 60px 20px;
			box-shadow: rgba(0,0,0,0.2) 0 5px 20px;
			color:#fff;
			@include tabletP{
				padding:30px 15px;
			}
		}

		.title{
			margin-bottom: 20px;
			text-align: center;
			@include tabletP{
				img{
					width: 150px;
				}
			}
		}
        .deco{
            margin-bottom: 30px;
            @include tabletP {
                width: 100px;
                height: auto;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 20px;
            }
        }

		.address{
			margin-bottom: 20px;
			font-family: $fontMincho;

		}

		.time{
			margin-bottom: 20px;
			font-family: $fontMincho;

		}
        .mado{
            margin-bottom: 10px;
            font-family: $fontMincho;
        }
        .tel{
            margin-bottom: 20px;
            font-family: $fontMincho;
            @include fz(30);
            line-height: 1em;
            @include tabletP{
                @include fz(25); 
            }
            span{
                @include fz(18);
                @include tabletP{
                    display: inline-block;
                    @include fz(15);
                }
            }
            a{
                color: #fff;
                text-decoration: none;
            }
            &.pc{
                @include sphoneP {
                    display: none; 
                }
            }
            &.sp{
                display: none;
                @include sphoneP {
                    display: inline-block; 
                }
            }
        }
		.btn{
			max-width: 330px;
			margin: 0 auto;
			@include tabletP{
				padding: 0 20px;
			}
		}
	}
	
	
	.ecBlock{
		background-image: url(../img/shop/bgShop03.png);
		background-position: center;
		padding: 80px 0;
		text-align: center;
		@include tabletP{
			padding: 30px 10px;
		}

	
		.title{
			margin-bottom: 20px;
			text-align: center;
			img{
				@include sphoneP {
					width: 50%; 
				}
			}
		}
		.mado{
			color: #fff;
			margin-bottom: 20px;
			font-family: $fontMincho;
		}
		
		.btn{
			max-width: 330px;
			margin: 0 auto;
			@include tabletP{
				padding: 0 20px;
			}
		}
	}
	
	
	
}


.container {
	
	@include margin-center;
	padding-right: $containerPadding;
	padding-left: $containerPadding;

}
#modPankuzu {
    color:#999;
    @include fz(12);
    margin: 20px 0;
    ul {
        li {
            display: inline-block;
            &:before{
                content:url(../img/common/iconArrowPan.png);
                background-size: cover;
                background-repeat: no-repeat;
                margin-left: 10px;
                margin-right: 10px;
            }
            &:first-child{
                position: relative;
                top:4px;
                &:before{
                    display: none;
                }
            }
            a {
                &:hover{
                }
            }
        }
    }

}


#contact{
}

#footer {
    .footerShopBlock{
        margin-left: -1%;
        margin-right: -1%;
        @include clearfix();
        .box{
            float: left;
            width: 31%;
            margin:0;
            padding: 0 2%;
            position: relative;
            @include tabletP {
                float: none;
                width: auto;
                border-bottom: 1px solid #ededed;
                padding-bottom: 20px;
                margin-bottom: 20px;
                &:last-child{
                    border-bottom: 0;
                }
            }
            &:after{
                content:"";
                width: 1px;
                height: 400px;
                background: #ededed;
                display: block;
                position: absolute;
                top:50px;
                right: 0;
                @include tabletP {
                    display: none;
                }
            }
            .open{
                height:1em;
                color:$mainColor;
                margin-bottom: 10px;
                @include fz(12);
                @include tabletP {
                    height:auto;
                }
            }
            .name{
                @include fz(16);
                font-weight: bold;
                font-family: $fontMincho;
            }
            .soon{
                @include fz(18);
                font-family: $fontMincho;
                line-height: 1.4;
                margin-top:60px;
                margin-bottom: 5px;
                @include tabletP {
                    margin-top: 0;
                    @include fz(14);
                }
            }
            .alert{
                color:$mainColor;
                @include fz(20);
                font-family: $fontMincho;
                @include tabletP {
                    @include fz(18);
                }
            }
        }
    }
	.info{
		background-color: #fff;
		padding: 50px 0;
		text-align: center;
		@include tabletP{
			padding: 30px 0;
		}

		.logo{
			margin-bottom: 30px;
		}

		.address{
			margin-bottom: 20px;
			font-family: $fontMincho;

		}

		.time{
			margin-bottom: 20px;
			font-family: $fontMincho;

		}

		.map{
			margin-bottom: 20px;
		}

		.btn{
			max-width: 330px;
			margin: 0 auto;
		}
        
	}

	.copyright{
		padding: 26px 0;
		background-color: #000;
		text-align: center;
		@include fz(12);
		color:#999;
		@include tabletP{
			padding: 15px 0;
		}
	}
}