@charset "utf-8";

/* modTable
------------------------------------------------------------*/
.modTbl01 {
    width: 100%;
    max-width: 980px;
    margin-left: auto;
    margin-right: auto;
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 2px;
    tr {
        th {
            padding: 15px 0;
            @include fz(20);
            background-color: $mainColor03;
            color:#fff;
            font-family: $fontMincho;
            text-align: center;
            @include tabletP{
                padding: 10px 0;
                @include fz(17);
            }
        }
        td {
            background-color: #fff;
            padding: 15px 20px;
            font-family: $fontMincho;
            @include fz(16);
            @include tabletP{
                @include fz(14);
                padding: 10px;
            }
    
        }
        .tit {
            background-color: #f9f1f3;
        }
        .subTit{
            text-align: left;
        }
    }
    &_course01{
       table-layout: auto;
       margin-bottom: 20px;
        td{
            &:nth-child(2){
                width: 13%;
            }
            &:nth-child(3){
                width: 15%;
            }
            &:nth-child(4){
                width: 25%;
            }
        }
        
    }
    &_course02{
        table-layout: auto;
        td{
            &:nth-child(2){
                width: 15%;
            }
            &:nth-child(3){
                width: 25%;
            }

        }
    }
}