@charset "utf-8";

/* modBtn
------------------------------------------------------------*/

.modBtn01{
	display: block;
	width: 100%;
	background-color: #000;
	box-sizing: border-box;
	transition:all 0.2s linear;
	border:solid 2px #fff;
	padding: 16px 18px;
	text-align: center;
	color:#fff;
	font-family: $fontMincho;
	@include fz(16);
	font-weight: bold;
	@include tabletP{
		@include fz(14);
	}

	&:hover{
		background-color: #fff;
		opacity: 1;
		color:#333;
		text-decoration: none;
	}

	&_type02{
		background-color: #fff;
		border-color:#000;
		color:#333;
		&:hover{
			background-color: #000;
			color:#fff;
		}
	}
    &_transparent{
        background-color: transparent;
    }

/* .modBtn01 */}

.modBtn02{
    display: block;
    width: 100%;
    max-width: 980px;
    margin-left: auto;
    margin-right: auto;
    background-color: $mainColor; 
    box-sizing: border-box;
    transition:all 0.2s linear;
    padding: 25px 0;
    letter-spacing: 1.78px;
    text-align: center;
    color:#fff;
    font-family: $fontMincho;
    @include fz(24);
    box-shadow: rgba(0,0,0,0.2) 0 5px 20px;
    position: relative;
    
    &:after{
        content:$fa-angle-right;
        @include iconFont();
        @include fz(28);
        position: absolute;
        right:35px;
        top:50%;
        bottom:0;
        margin-top:-0.5em;
    }
    &:hover{
        text-decoration: none;
        color:#fff;
        
    }
    @include tabletP{
        @include fz(14);
    }
    
}
.modBtnList{
	max-width: 330px;
	margin: 0 auto;
	@include tabletP{
		padding: 0 20px;
	}

	&_type02{
		max-width: 342px;
		@include clearfix;

		li{
			float: left;
			width: 46%;
			&:last-child{
				float: right;
			}
		}
	}

/* .modBtnList */}