@charset "utf-8";

/* modTitle
------------------------------------------------------------*/
#modMainTitle{
    background-image: url(../img/course/bgMainTitle.png);
    background-size: cover;
    background-repeat: no-repeat;
    font-family: $fontMincho;
    color:#fff;
    min-height: 450px;
    background-position: 63%;
    @media only screen and (max-width: 1200px){
        background-size: 200%;
    }
    @include tabletP{
        background-size: cover;
        min-height: 300px;
    }
    @include sphoneP{
        background-color: #000;
        background-size: 200%;
        background-position: 95% top;
    }
    .mainTitle{
        @include fz(50);
        font-style:italic;
        margin-top: 60px;
        @include tabletP{
            @include fz(26);
        }
        @include sphoneP{
            text-align: center;
            margin-top: 20vh;
        }
    }
    .catch {
        @include fz(18);
        font-style:italic;
        @include tabletP{
            @include fz(12);
            
        }
        @include sphoneP{
            text-align: center;
        }
    }
    #first & {
        background-image: url(../img/first/bgMainTitle.png);
    }
    #course & {
        background-image: url(../img/course/bgMainTitle.png);
    }
				#course.body & {
					background-image: url(../img/course/bgMainTitle_body.png);
				}
    #staff & {
        background-image: url(../img/staff/bgMainTitle.png);
    }
    #company & {
        background-image: url(../img/company/bgMainTitle.png);
    }
    #shop & {
        background-image: url(../img/shop/bgMainTitle.png);
    }
}
.modTitle01{
    font-family: $fontMincho;
    @include fz(36);
    color:#000;
    margin-bottom: 40px;
    font-weight: bold;
    display: inline-block;
    position: relative;
    @include tabletP{
        @include fz(26);
    }
    &:after{
        content:"";
        display: block;
        background-image: url(../img/common/decoTitleBottom.png);
        background-repeat: no-repeat;
        background-position: center center;
        width: 221px;
        height: 36px;
        margin: 20px auto 0;
        @include tabletP{
            width: 110px;
            height: 18px;
            background-size: 100%;
            margin: 0px auto 0;
        }
    }
    &_white{
        color:#fff;
    }
    span{
        @include fz(20);
        display: block;
        @include tabletP{
            @include fz(12);
        }
    }
    .deco{
        position: absolute;
        bottom:20px;
        @include tabletP{
            width: 60px;
            bottom:0px;
        }
        &_left{
            left:-120px;
            @include tabletP{
                left: -70px
            }
            &.deco_in{
                @include tabletP{
                    left: -40px
                } 
            }
        }
        &_right{
            right:-120px;
            @include tabletP{
                right: -70px
            }
            &.deco_in{
                @include tabletP{
                    right: -40px
                } 
            }
        }
    }
}
.modTitle02{
	color: #fff;
	font-family: $fontMincho;
	@include fz(24);
	margin-bottom: 10px;
	text-align: left;
	position: relative;
	padding-left: 50px;
	letter-spacing: -2px;
	@include sphoneP {
		@include fz(14); 
		line-height: 1.2;
		margin-bottom: 18px;
		height: 2em;
	}
	&:before{
	
			content: "";
			display: block;
			width: 54px;
			height: 49px;
			background-image: url(../img/common/decoSubTitle.png);
			background-repeat: no-repeat;
			background-position: left center;
			position: absolute;
			left: -15px;
			top: -5px;
			margin-top: -2px;
		@include sphoneP {
			margin-top: -4px; 
		}
	}
	span{
		@include sphoneP {
			display: block; 
		}
	}
	&_line1{
		@include sphoneP {
			padding-top: 1em; 
		}
	}
}
