@charset "UTF-8";
@import url(font-awesome.min.css);
@font-face {
  font-family: "游明朝体";
  font-weight: normal;
  src: local("游明朝体"), local("游明朝体"), local("游明朝体");
  /* Mediumがない場合 */
}

@font-face {
  font-family: "Yu Mincho";
  font-weight: bold;
  src: local("Yu Mincho"), local("Yu Mincho");
  /* for Chrome */
}

/*@mixin tabletL {
  //タブレット横の場合
  @media only screen and (max-width: $bpTabletL) {
	  @content;
  }
}*/
/* Reset
------------------------------------------------------------*/
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

address,
em {
  font-style: normal;
}

strong,
th {
  font-weight: normal;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

th {
  text-align: left;
}

hr,
legend {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

img,
fieldset {
  border: 0;
}

img {
  border: 0;
  vertical-align: top;
  max-width: 100%;
  height: auto;
}

li {
  list-style-type: none;
}

input[type="submit"], button, label, select {
  cursor: pointer;
}

input[type="text"], input[type="tel"], input[type="email"], input[type="password"], input[type="button"], textarea, input[type="submit"], input[type="password"], button, label {
  -webkit-appearance: none;
  border-radius: 0;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* link
------------------------------------------------------------*/
a {
  color: #333;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  color: #333;
  opacity: 0.8;
}

button:hover {
  opacity: 0.8;
}

/* Fonts
------------------------------------------------------------*/
html {
  font-size: 62.5%;
}

/* 10px base */
body {
  -webkit-text-size-adjust: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

body > div, input, button, textarea, select {
  font-size: 14px;
  font-size: 1.4rem;
  font-family: "メイリオ", Verdana, "Hiragino Kaku Gothic ProN", "游ゴシック", YuGothic, Meiryo, sans-serif;
  line-height: 1.8;
  color: #333;
}

@media only screen and (max-width: 992px) {
  body > div, input, button, textarea, select {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

/* form
------------------------------------------------------------*/
select {
  padding: 10px;
  border-radius: 5px;
}

textarea,
input[type="text"],
input[type="password"],
input[type="email"],
select {
  padding: 10px;
  border-radius: 5px;
  border: solid 1px #aaa;
}

textarea.error,
input[type="text"].error,
input[type="password"].error,
input[type="email"].error,
select.error {
  background-color: #fff2f2;
  border: solid 2px #e4007f;
}

/* radio
-------------*/
input[type="radio"] {
  display: none;
}

input[type="radio"] + label {
  position: relative;
  display: inline-block;
  padding: 3px 10px 3px 24px;
  cursor: pointer;
  width: 100%;
}

input[type="radio"] + label::before,
input[type="radio"] + label::after {
  content: '';
  top: 50%;
  position: absolute;
  border-radius: 100%;
  transition: all .2s;
}

input[type="radio"] + label::before {
  width: 16px;
  height: 16px;
  margin-top: -9px;
  left: 0;
  background: #EEE;
  border: 1px solid #ccc;
}

input[type="radio"].error + label::before {
  border: 1px solid #e4007f;
  background-color: #fff2f2;
}

input[type="radio"] + label:hover::before {
  background: #FEFEFE;
}

input[type="radio"] + label::after {
  opacity: 0;
  left: 3px;
  width: 10px;
  height: 10px;
  margin-top: -6px;
  background: #3498DB;
  transform: scale(0.5);
}

input[type="radio"]:checked + label::before {
  background: #fff;
  border: 1px solid #3498DB;
}

input[type="radio"]:checked + label::after {
  opacity: 1;
  transform: scale(1);
}

/* checkbox
-------------*/
input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] + label {
  position: relative;
  display: inline-block;
  padding: 3px 10px 3px 22px;
  cursor: pointer;
  -webkit-transition: all .2s;
  transition: all .2s;
}

input[type="checkbox"] + label::before,
input[type="checkbox"] + label::after {
  position: absolute;
  content: '';
}

input[type="checkbox"] + label::before {
  top: 50%;
  left: 0;
  width: 14px;
  height: 14px;
  margin-top: -8px;
  background: #f4f4f4;
  border: 1px solid #ccc;
  border-radius: 3px;
}

input[type="checkbox"] + label::after {
  opacity: 0;
  top: 50%;
  left: 3px;
  width: 8px;
  height: 4px;
  margin-top: -4px;
  border-left: 2px solid #3498DB;
  border-bottom: 2px solid #3498DB;
  transform: rotate(-45deg) scale(0.5);
}

input[type="checkbox"] + label:hover::before {
  background: #fff;
}

input[type="checkbox"]:checked + label::before {
  background: #fff;
  border: 1px solid #3498DB;
}

input[type="checkbox"]:checked + label::after {
  opacity: 1;
  transform: rotate(-45deg) scale(1);
}

html {
  background-color: #000;
}

body {
  background-color: #fff;
}

/* layout */
#header {
  background-color: rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width: 600px) {
  #header:after {
    content: ".";
    display: block;
    clear: both;
    height: 0;
    visibility: hidden;
  }
}

#header .container {
  display: table;
  width: 100%;
}

@media only screen and (max-width: 992px) {
  #header .container {
    display: block;
    padding: 0;
  }
}

@media only screen and (max-width: 992px) {
  #header .head {
    sposition: relative;
  }
}

@media only screen and (max-width: 992px) {
  #header .head .siteTitle {
    width: 25%;
    float: left;
    padding-left: 2%;
  }
}

@media only screen and (max-width: 600px) {
  #header .head .siteTitle {
    margin-top: 5px;
    width: 35%;
  }
}

#header .head #spNav {
  display: none;
}

@media only screen and (max-width: 992px) {
  #header .head #spNav {
    display: block;
    padding-left: 140px;
  }
}

@media only screen and (max-width: 992px) {
  #header .head #spNav ul {
    display: table;
    table-layout: fixed;
    width: 60%;
    text-align: right;
  }
}

@media only screen and (max-width: 992px) {
  #header .head #spNav ul li {
    display: table-cell;
    vertical-align: middle;
    height: 60px;
  }
}

@media only screen and (max-width: 992px) {
  #header .head #spNav ul li img {
    width: auto;
    height: 20px;
  }
}

#header .head #menu {
  display: none;
}

@media only screen and (max-width: 992px) {
  #header .head #menu {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 56px;
  }
}

@media only screen and (max-width: 992px) {
  #header .foot {
    display: none;
    position: absolute;
    width: 100%;
  }
}

#header .siteTitle, #header .contact {
  display: table-cell;
  vertical-align: middle;
}

#header .siteTitle {
  padding: 10px 0;
  float: left;
}

@media only screen and (max-width: 992px) {
  #header .siteTitle {
    width: 130px;
    padding: 8px 0;
  }
}

#header .contact, #header .ec {
  float: right;
  text-align: right;
  padding: 20px 0;
}

@media only screen and (max-width: 1200px) {
  #header .contact, #header .ec {
    font-size: 10px;
    font-size: 1rem;
  }
  #header .contact span, #header .ec span {
    display: block;
  }
}

@media only screen and (max-width: 992px) {
  #header .contact, #header .ec {
    padding: 8px 0;
  }
}

@media only screen and (max-width: 992px) {
  #header .contact, #header .ec {
    display: none;
  }
}

#header .contact a, #header .ec a {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  background-color: #000;
  padding: 10px 30px;
  color: #fff;
  font-family: "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", "メイリオ", Meiryo, serif;
  text-align: center;
  text-decoration: none;
}

@media only screen and (max-width: 1200px) {
  #header .contact a, #header .ec a {
    padding: 8px;
    height: 40px;
    line-height: 1.4;
  }
}

@media only screen and (max-width: 992px) {
  #header .contact a, #header .ec a {
    padding: 8px 10px;
    font-size: 10px;
    font-size: 1rem;
  }
}

@media only screen and (max-width: 1200px) {
  #header .contact a {
    padding-top: 12px;
  }
}

#header .ec {
  margin-right: 10px;
}

#header .close {
  display: none;
}

@media only screen and (max-width: 992px) {
  #header .close {
    position: relative;
    z-index: 2000;
    display: block;
    background: #000;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 992px) {
  #header .close span {
    width: 80%;
    background: #111;
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 3px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    color: #fff;
  }
}

#gNav {
  float: left;
  margin-left: 30px;
}

@media only screen and (max-width: 992px) {
  #gNav {
    background: #000;
    float: none;
    z-index: 2000;
    margin-left: 0;
    position: relative;
  }
}

#gNav ul {
  display: table;
  table-layout: fixed;
}

@media only screen and (max-width: 992px) {
  #gNav ul {
    display: block;
    width: 100%;
  }
}

#gNav li {
  display: table-cell;
  vertical-align: middle;
  font-family: "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", "メイリオ", Meiryo, serif;
}

@media only screen and (max-width: 992px) {
  #gNav li {
    display: block;
    width: 100%;
  }
}

#gNav li a {
  color: #fff;
  padding: 30px 10px;
  display: block;
  position: relative;
}

@media only screen and (max-width: 992px) {
  #gNav li a {
    padding: 15px;
    text-align: center;
    border-bottom: 1px solid #1f1f1f;
  }
}

#gNav li a:hover {
  text-decoration: none;
}

#gNav li a:hover:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #e64398;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

@media only screen and (max-width: 600px) {
  #gNav li a:hover:after {
    display: none;
  }
}

#gNav li.spOnly {
  display: none;
}

@media only screen and (max-width: 992px) {
  #gNav li.spOnly {
    display: inline-block;
  }
}

#top .navHome a:after,
#first .navFirst a:after,
#course .navCourse a:after,
#staff .navStaff a:after,
#shop .navShop a:after,
#company .navCompany a:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #e64398;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

@media only screen and (max-width: 600px) {
  #top .navHome a:after,
  #first .navFirst a:after,
  #course .navCourse a:after,
  #staff .navStaff a:after,
  #shop .navShop a:after,
  #company .navCompany a:after {
    display: none;
  }
}

#contents .mainVisual {
  background-image: url(../img/top/bgMainVisual.jpg);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 760px;
}

@media only screen and (max-width: 992px) {
  #contents .mainVisual {
    background-image: url(../img/top/bgMainVisual@2x.jpg);
    height: 120vw;
  }
}

#contents .mainVisual .logo {
  position: absolute;
  bottom: 40px;
}

@media only screen and (max-width: 992px) {
  #contents .mainVisual .logo {
    bottom: 5vw;
  }
}

#contents .menuBlock {
  background-image: url(../img/common/bg01.jpg);
  background-position: center top;
  padding: 60px 0;
}

@media only screen and (max-width: 992px) {
  #contents .menuBlock {
    padding: 30px 0;
  }
}

#contents .menuBlock .title {
  margin-bottom: 50px;
  text-align: center;
}

@media only screen and (max-width: 992px) {
  #contents .menuBlock .title {
    margin-bottom: 30px;
  }
  #contents .menuBlock .title img {
    width: 100px;
  }
}

#contents .menuBlock .menu {
  max-width: 980px;
  margin: 0 auto 60px;
}

#contents .menuBlock .menu:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

@media only screen and (max-width: 992px) {
  #contents .menuBlock .menu {
    padding: 0 30px;
    margin-bottom: 30px;
  }
}

#contents .menuBlock .menu li {
  float: left;
  width: 49%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 5px 20px;
}

@media only screen and (max-width: 992px) {
  #contents .menuBlock .menu li {
    float: none;
    width: auto;
    text-align: center;
  }
}

#contents .menuBlock .menu li:last-child {
  float: right;
}

@media only screen and (max-width: 992px) {
  #contents .menuBlock .menu li:last-child {
    float: none;
    margin-top: 30px;
  }
}

#contents .menuBlock .btn {
  max-width: 330px;
  margin: 0 auto;
}

@media only screen and (max-width: 992px) {
  #contents .menuBlock .btn {
    padding: 0 20px;
  }
}

#contents .contactBlock {
  background-image: url(../img/common/bg02.jpg);
  background-size: cover;
  background-position: center;
  padding: 80px 0;
  text-align: center;
}

@media only screen and (max-width: 992px) {
  #contents .contactBlock {
    padding: 30px 10px;
  }
}

#contents .contactBlock .body {
  background-color: #000;
  max-width: 550px;
  margin: 0 auto;
  padding: 60px 20px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 5px 20px;
  color: #fff;
}

@media only screen and (max-width: 992px) {
  #contents .contactBlock .body {
    padding: 30px 15px;
  }
}

#contents .contactBlock .title {
  margin-bottom: 20px;
  text-align: center;
}

@media only screen and (max-width: 992px) {
  #contents .contactBlock .title img {
    width: 150px;
  }
}

#contents .contactBlock .deco {
  margin-bottom: 30px;
}

@media only screen and (max-width: 992px) {
  #contents .contactBlock .deco {
    width: 100px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }
}

#contents .contactBlock .address {
  margin-bottom: 20px;
  font-family: "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", "メイリオ", Meiryo, serif;
}

#contents .contactBlock .time {
  margin-bottom: 20px;
  font-family: "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", "メイリオ", Meiryo, serif;
}

#contents .contactBlock .mado {
  margin-bottom: 10px;
  font-family: "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", "メイリオ", Meiryo, serif;
}

#contents .contactBlock .tel {
  margin-bottom: 20px;
  font-family: "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", "メイリオ", Meiryo, serif;
  font-size: 30px;
  font-size: 3rem;
  line-height: 1em;
}

@media only screen and (max-width: 992px) {
  #contents .contactBlock .tel {
    font-size: 25px;
    font-size: 2.5rem;
  }
}

#contents .contactBlock .tel span {
  font-size: 18px;
  font-size: 1.8rem;
}

@media only screen and (max-width: 992px) {
  #contents .contactBlock .tel span {
    display: inline-block;
    font-size: 15px;
    font-size: 1.5rem;
  }
}

#contents .contactBlock .tel a {
  color: #fff;
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  #contents .contactBlock .tel.pc {
    display: none;
  }
}

#contents .contactBlock .tel.sp {
  display: none;
}

@media only screen and (max-width: 600px) {
  #contents .contactBlock .tel.sp {
    display: inline-block;
  }
}

#contents .contactBlock .btn {
  max-width: 330px;
  margin: 0 auto;
}

@media only screen and (max-width: 992px) {
  #contents .contactBlock .btn {
    padding: 0 20px;
  }
}

#contents .ecBlock {
  background-image: url(../img/shop/bgShop03.png);
  background-position: center;
  padding: 80px 0;
  text-align: center;
}

@media only screen and (max-width: 992px) {
  #contents .ecBlock {
    padding: 30px 10px;
  }
}

#contents .ecBlock .title {
  margin-bottom: 20px;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  #contents .ecBlock .title img {
    width: 50%;
  }
}

#contents .ecBlock .mado {
  color: #fff;
  margin-bottom: 20px;
  font-family: "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", "メイリオ", Meiryo, serif;
}

#contents .ecBlock .btn {
  max-width: 330px;
  margin: 0 auto;
}

@media only screen and (max-width: 992px) {
  #contents .ecBlock .btn {
    padding: 0 20px;
  }
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-right: 10px;
  padding-left: 10px;
}

#modPankuzu {
  color: #999;
  font-size: 12px;
  font-size: 1.2rem;
  margin: 20px 0;
}

#modPankuzu ul li {
  display: inline-block;
}

#modPankuzu ul li:before {
  content: url(../img/common/iconArrowPan.png);
  background-size: cover;
  background-repeat: no-repeat;
  margin-left: 10px;
  margin-right: 10px;
}

#modPankuzu ul li:first-child {
  position: relative;
  top: 4px;
}

#modPankuzu ul li:first-child:before {
  display: none;
}

#footer .footerShopBlock {
  margin-left: -1%;
  margin-right: -1%;
}

#footer .footerShopBlock:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

#footer .footerShopBlock .box {
  float: left;
  width: 31%;
  margin: 0;
  padding: 0 2%;
  position: relative;
}

@media only screen and (max-width: 992px) {
  #footer .footerShopBlock .box {
    float: none;
    width: auto;
    border-bottom: 1px solid #ededed;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  #footer .footerShopBlock .box:last-child {
    border-bottom: 0;
  }
}

#footer .footerShopBlock .box:after {
  content: "";
  width: 1px;
  height: 400px;
  background: #ededed;
  display: block;
  position: absolute;
  top: 50px;
  right: 0;
}

@media only screen and (max-width: 992px) {
  #footer .footerShopBlock .box:after {
    display: none;
  }
}

#footer .footerShopBlock .box .open {
  height: 1em;
  color: #e64398;
  margin-bottom: 10px;
  font-size: 12px;
  font-size: 1.2rem;
}

@media only screen and (max-width: 992px) {
  #footer .footerShopBlock .box .open {
    height: auto;
  }
}

#footer .footerShopBlock .box .name {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
  font-family: "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", "メイリオ", Meiryo, serif;
}

#footer .footerShopBlock .box .soon {
  font-size: 18px;
  font-size: 1.8rem;
  font-family: "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", "メイリオ", Meiryo, serif;
  line-height: 1.4;
  margin-top: 60px;
  margin-bottom: 5px;
}

@media only screen and (max-width: 992px) {
  #footer .footerShopBlock .box .soon {
    margin-top: 0;
    font-size: 14px;
    font-size: 1.4rem;
  }
}

#footer .footerShopBlock .box .alert {
  color: #e64398;
  font-size: 20px;
  font-size: 2rem;
  font-family: "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", "メイリオ", Meiryo, serif;
}

@media only screen and (max-width: 992px) {
  #footer .footerShopBlock .box .alert {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

#footer .info {
  background-color: #fff;
  padding: 50px 0;
  text-align: center;
}

@media only screen and (max-width: 992px) {
  #footer .info {
    padding: 30px 0;
  }
}

#footer .info .logo {
  margin-bottom: 30px;
}

#footer .info .address {
  margin-bottom: 20px;
  font-family: "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", "メイリオ", Meiryo, serif;
}

#footer .info .time {
  margin-bottom: 20px;
  font-family: "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", "メイリオ", Meiryo, serif;
}

#footer .info .map {
  margin-bottom: 20px;
}

#footer .info .btn {
  max-width: 330px;
  margin: 0 auto;
}

#footer .copyright {
  padding: 26px 0;
  background-color: #000;
  text-align: center;
  font-size: 12px;
  font-size: 1.2rem;
  color: #999;
}

@media only screen and (max-width: 992px) {
  #footer .copyright {
    padding: 15px 0;
  }
}

/* CSS Document */
.container {
  max-width: 970px;
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

@media only screen and (max-width: 992px) {
  .container {
    max-width: 750px;
  }
}

@media only screen and (max-width: 992px) {
  .modPcOnly {
    display: none;
  }
}

.modSpOnly {
  display: none;
}

@media only screen and (max-width: 992px) {
  .modSpOnly {
    display: block;
  }
}

/* modTitle
------------------------------------------------------------*/
#modMainTitle {
  background-image: url(../img/course/bgMainTitle.png);
  background-size: cover;
  background-repeat: no-repeat;
  font-family: "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", "メイリオ", Meiryo, serif;
  color: #fff;
  min-height: 450px;
  background-position: 63%;
}

@media only screen and (max-width: 1200px) {
  #modMainTitle {
    background-size: 200%;
  }
}

@media only screen and (max-width: 992px) {
  #modMainTitle {
    background-size: cover;
    min-height: 300px;
  }
}

@media only screen and (max-width: 600px) {
  #modMainTitle {
    background-color: #000;
    background-size: 200%;
    background-position: 95% top;
  }
}

#modMainTitle .mainTitle {
  font-size: 50px;
  font-size: 5rem;
  font-style: italic;
  margin-top: 60px;
}

@media only screen and (max-width: 992px) {
  #modMainTitle .mainTitle {
    font-size: 26px;
    font-size: 2.6rem;
  }
}

@media only screen and (max-width: 600px) {
  #modMainTitle .mainTitle {
    text-align: center;
    margin-top: 20vh;
  }
}

#modMainTitle .catch {
  font-size: 18px;
  font-size: 1.8rem;
  font-style: italic;
}

@media only screen and (max-width: 992px) {
  #modMainTitle .catch {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 600px) {
  #modMainTitle .catch {
    text-align: center;
  }
}

#first #modMainTitle {
  background-image: url(../img/first/bgMainTitle.png);
}

#course #modMainTitle {
  background-image: url(../img/course/bgMainTitle.png);
}

#course.body #modMainTitle {
  background-image: url(../img/course/bgMainTitle_body.png);
}

#staff #modMainTitle {
  background-image: url(../img/staff/bgMainTitle.png);
}

#company #modMainTitle {
  background-image: url(../img/company/bgMainTitle.png);
}

#shop #modMainTitle {
  background-image: url(../img/shop/bgMainTitle.png);
}

.modTitle01 {
  font-family: "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", "メイリオ", Meiryo, serif;
  font-size: 36px;
  font-size: 3.6rem;
  color: #000;
  margin-bottom: 40px;
  font-weight: bold;
  display: inline-block;
  position: relative;
}

@media only screen and (max-width: 992px) {
  .modTitle01 {
    font-size: 26px;
    font-size: 2.6rem;
  }
}

.modTitle01:after {
  content: "";
  display: block;
  background-image: url(../img/common/decoTitleBottom.png);
  background-repeat: no-repeat;
  background-position: center center;
  width: 221px;
  height: 36px;
  margin: 20px auto 0;
}

@media only screen and (max-width: 992px) {
  .modTitle01:after {
    width: 110px;
    height: 18px;
    background-size: 100%;
    margin: 0px auto 0;
  }
}

.modTitle01_white {
  color: #fff;
}

.modTitle01 span {
  font-size: 20px;
  font-size: 2rem;
  display: block;
}

@media only screen and (max-width: 992px) {
  .modTitle01 span {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.modTitle01 .deco {
  position: absolute;
  bottom: 20px;
}

@media only screen and (max-width: 992px) {
  .modTitle01 .deco {
    width: 60px;
    bottom: 0px;
  }
}

.modTitle01 .deco_left {
  left: -120px;
}

@media only screen and (max-width: 992px) {
  .modTitle01 .deco_left {
    left: -70px;
  }
}

@media only screen and (max-width: 992px) {
  .modTitle01 .deco_left.deco_in {
    left: -40px;
  }
}

.modTitle01 .deco_right {
  right: -120px;
}

@media only screen and (max-width: 992px) {
  .modTitle01 .deco_right {
    right: -70px;
  }
}

@media only screen and (max-width: 992px) {
  .modTitle01 .deco_right.deco_in {
    right: -40px;
  }
}

.modTitle02 {
  color: #fff;
  font-family: "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", "メイリオ", Meiryo, serif;
  font-size: 24px;
  font-size: 2.4rem;
  margin-bottom: 10px;
  text-align: left;
  position: relative;
  padding-left: 50px;
  letter-spacing: -2px;
}

@media only screen and (max-width: 600px) {
  .modTitle02 {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.2;
    margin-bottom: 18px;
    height: 2em;
  }
}

.modTitle02:before {
  content: "";
  display: block;
  width: 54px;
  height: 49px;
  background-image: url(../img/common/decoSubTitle.png);
  background-repeat: no-repeat;
  background-position: left center;
  position: absolute;
  left: -15px;
  top: -5px;
  margin-top: -2px;
}

@media only screen and (max-width: 600px) {
  .modTitle02:before {
    margin-top: -4px;
  }
}

@media only screen and (max-width: 600px) {
  .modTitle02 span {
    display: block;
  }
}

@media only screen and (max-width: 600px) {
  .modTitle02_line1 {
    padding-top: 1em;
  }
}

/* modTxt
------------------------------------------------------------*/
/* modBtn
------------------------------------------------------------*/
.modBtn01 {
  display: block;
  width: 100%;
  background-color: #000;
  box-sizing: border-box;
  transition: all 0.2s linear;
  border: solid 2px #fff;
  padding: 16px 18px;
  text-align: center;
  color: #fff;
  font-family: "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", "メイリオ", Meiryo, serif;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
  /* .modBtn01 */
}

@media only screen and (max-width: 992px) {
  .modBtn01 {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.modBtn01:hover {
  background-color: #fff;
  opacity: 1;
  color: #333;
  text-decoration: none;
}

.modBtn01_type02 {
  background-color: #fff;
  border-color: #000;
  color: #333;
}

.modBtn01_type02:hover {
  background-color: #000;
  color: #fff;
}

.modBtn01_transparent {
  background-color: transparent;
}

.modBtn02 {
  display: block;
  width: 100%;
  max-width: 980px;
  margin-left: auto;
  margin-right: auto;
  background-color: #e64398;
  box-sizing: border-box;
  transition: all 0.2s linear;
  padding: 25px 0;
  letter-spacing: 1.78px;
  text-align: center;
  color: #fff;
  font-family: "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", "メイリオ", Meiryo, serif;
  font-size: 24px;
  font-size: 2.4rem;
  box-shadow: rgba(0, 0, 0, 0.2) 0 5px 20px;
  position: relative;
}

.modBtn02:after {
  content: "";
  font-family: FontAwesome;
  line-height: 1;
  font-size: 28px;
  font-size: 2.8rem;
  position: absolute;
  right: 35px;
  top: 50%;
  bottom: 0;
  margin-top: -0.5em;
}

.modBtn02:hover {
  text-decoration: none;
  color: #fff;
}

@media only screen and (max-width: 992px) {
  .modBtn02 {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.modBtnList {
  max-width: 330px;
  margin: 0 auto;
  /* .modBtnList */
}

@media only screen and (max-width: 992px) {
  .modBtnList {
    padding: 0 20px;
  }
}

.modBtnList_type02 {
  max-width: 342px;
}

.modBtnList_type02:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

.modBtnList_type02 li {
  float: left;
  width: 46%;
}

.modBtnList_type02 li:last-child {
  float: right;
}

/* modBlock
------------------------------------------------------------*/
#modAnkerBlock .list {
  font-size: 16px;
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  margin-left: -0.5%;
  margin-bottom: 80px;
}

@media only screen and (max-width: 992px) {
  #modAnkerBlock .list {
    display: block;
    margin-bottom: 30px;
    font-size: 14px;
    font-size: 1.4rem;
  }
}

#modAnkerBlock .list li {
  width: 23%;
  margin-right: 1%;
  display: table;
}

@media only screen and (max-width: 992px) {
  #modAnkerBlock .list li {
    display: block;
    width: auto;
    margin-bottom: 5px;
  }
  #modAnkerBlock .list li br {
    display: none;
  }
}

#modAnkerBlock .list li a {
  display: table-cell;
  vertical-align: middle;
  padding: 17px 0 25px;
  border: 1px solid #ccc;
  border-radius: 4px;
  line-height: 1.4;
  height: 80px;
  text-align: center;
  color: #333;
  font-family: "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", "メイリオ", Meiryo, serif;
  position: relative;
}

@media only screen and (max-width: 992px) {
  #modAnkerBlock .list li a {
    text-align: left;
    height: auto;
    display: block;
    padding: 4% 8% 4% 4%;
  }
}

#modAnkerBlock .list li a:hover {
  background-color: #fef5fa;
  border: 1px solid #e64398;
  text-decoration: none;
}

#modAnkerBlock .list li a:after {
  content: "";
  font-family: FontAwesome;
  line-height: 1;
  position: absolute;
  font-size: 14px;
  font-size: 1.4rem;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 8px;
  color: #e64398;
}

@media only screen and (max-width: 992px) {
  #modAnkerBlock .list li a:after {
    left: auto;
    right: 10px;
    top: 50%;
    bottom: auto;
    margin-top: -0.5em;
  }
}

.secBlock {
  background-image: url(../img/common/bg02.jpg);
  background-size: cover;
  background-position: center bottom;
  padding: 60px 0;
  text-align: center;
}

@media only screen and (max-width: 992px) {
  .secBlock {
    padding: 30px 10px;
  }
}

.secBlock_type01 {
  background-image: url(../img/common/bg01.png);
}

.secBlock_type02 {
  background-image: url(../img/common/bg03.jpg);
}

.secBlock_type03 {
  background-image: url(../img/common/bg01.jpg);
  background-repeat: repeat;
  background-size: auto;
}

.secBlock_type04 {
  background-image: url(../img/common/bg02.png);
}

.secBlock_type05 {
  background: transparent;
  background-color: #f8f4f4;
}

.secBlock_type06 {
  background-image: url(../img/common/bg03_2.jpg);
}

.secBlock_type07 {
  background-image: url(../img/common/bg05.png);
  background-repeat: repeat;
  background-size: auto;
}

.secBlock_type08 {
  background-image: url(../img/common/bg06.png);
  background-repeat: repeat;
  background-size: auto;
}

.secBlock_flash {
  position: relative;
}

.secBlock_flash:before {
  content: "";
  background-image: url(../img/common/bgFlash.png);
  background-size: cover;
  background-position: center top;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.secBlock_typeShop01 {
  background-image: url(../img/shop/bgShop01.png);
}

.secBlock_typeShop02 {
  background-image: url(../img/shop/bgShop02.png);
}

.secBlock_typeShop03 {
  background-image: url(../img/shop/bgShop03.png);
}

.secBlock .container {
  position: relative;
  z-index: 1;
}

.secBlock .body {
  text-align: left;
  background-color: #fff;
  max-width: 980px;
  margin: 0 auto;
  padding: 60px 20px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 5px 20px;
  color: #000;
}

.secBlock .body_type01 {
  background-image: url(../img/company/bg01.png);
  background-size: cover;
  background-position: center top;
}

@media only screen and (max-width: 992px) {
  .secBlock .body_type01 {
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: right top;
  }
}

.secBlock .body_alpha {
  background-color: rgba(255, 255, 255, 0.9);
}

@media only screen and (max-width: 992px) {
  .secBlock .body {
    padding: 30px 15px;
  }
}

.modBlockList01 .box {
  background-color: #fff;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.2) 0 5px 20px;
  margin-bottom: 40px;
  padding: 40px 40px 40px 70px;
  text-align: left;
  max-width: 980px;
  margin-left: auto;
  margin-right: auto;
}

.modBlockList01 .box:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

@media only screen and (max-width: 992px) {
  .modBlockList01 .box {
    padding: 40px;
  }
}

.modBlockList01 .box:before {
  content: "";
  border: 1px solid #c29c9c;
  display: block;
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
}

.modBlockList01 .box .text {
  float: left;
  width: 64%;
}

@media only screen and (max-width: 992px) {
  .modBlockList01 .box .text {
    float: none;
    width: 100%;
  }
  .modBlockList01 .box .text br {
    display: none;
  }
}

.modBlockList01 .box .text .step {
  margin-bottom: 15px;
}

@media only screen and (max-width: 992px) {
  .modBlockList01 .box .text .step {
    margin-bottom: 10px;
  }
  .modBlockList01 .box .text .step img {
    max-width: 80px;
  }
}

.modBlockList01 .box .text .title {
  font-size: 28px;
  font-size: 2.8rem;
  font-family: "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", "メイリオ", Meiryo, serif;
  letter-spacing: 1.78px;
  line-height: 1.4;
  color: #000;
  margin-bottom: 10px;
}

@media only screen and (max-width: 992px) {
  .modBlockList01 .box .text .title {
    font-size: 23px;
    font-size: 2.3rem;
  }
}

.modBlockList01 .box .text p {
  font-size: 16px;
  font-size: 1.6rem;
  font-family: "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", "メイリオ", Meiryo, serif;
}

@media only screen and (max-width: 992px) {
  .modBlockList01 .box .text p {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.modBlockList01 .box .text sup {
  font-size: 70%;
}

.modBlockList01 .box .text .caution {
  font-size: 87%;
}

.modBlockList01 .box .image {
  float: right;
  width: 36%;
  text-align: center;
}

@media only screen and (max-width: 992px) {
  .modBlockList01 .box .image {
    float: none;
    width: 100%;
  }
}

.modBlockList01_type02 .box {
  padding: 50px 40px 50px 70px;
}

@media only screen and (max-width: 992px) {
  .modBlockList01_type02 .box {
    padding: 40px;
  }
}

.modBlockList01_type02 .box .text {
  width: 62%;
}

@media only screen and (max-width: 992px) {
  .modBlockList01_type02 .box .text {
    width: 100%;
  }
}

.modBlockList01_type02 .box .text .title {
  color: #c96a84;
}

.modBlockList01_type02 .box .text .title span {
  font-size: 22px;
  font-size: 2.2rem;
  letter-spacing: 1px;
}

@media only screen and (max-width: 992px) {
  .modBlockList01_type02 .box .text .title span {
    font-size: 20px;
    font-size: 2rem;
  }
}

.modBlockList01_type02 .box .image {
  text-align: right;
}

@media only screen and (max-width: 992px) {
  .modBlockList01_type02 .box .image {
    margin-top: 20px;
    text-align: center;
  }
}

.modBlockList02 {
  max-width: 980px;
  margin: 0 auto;
}

.modBlockList02:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

.modBlockList02 .box {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.2) 0 5px 20px;
  width: 30%;
  margin: 0 1.6666% 40px;
  float: left;
}

@media only screen and (max-width: 992px) {
  .modBlockList02 .box {
    width: 47.5%;
    margin: 0 1.25% 20px;
  }
}

@media only screen and (max-width: 600px) {
  .modBlockList02 .box {
    width: 100%;
    margin: 0 0 20px;
  }
}

.modBlockList02 .box .image {
  position: relative;
}

.modBlockList02 .box .image img {
  width: 100%;
  height: auto;
}

.modBlockList02 .box .image .shop {
  background-color: #e64398;
  color: #fff;
  padding: 0px 10px;
  font-size: 14px;
  font-size: 1.4rem;
  font-family: "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", "メイリオ", Meiryo, serif;
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.modBlockList02 .box .text {
  font-family: "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", "メイリオ", Meiryo, serif;
}

.modBlockList02 .box .text .name {
  margin-top: 12px;
  font-size: 24px;
  font-size: 2.4rem;
}

@media only screen and (max-width: 992px) {
  .modBlockList02 .box .text .name {
    font-size: 20px;
    font-size: 2rem;
  }
}

.modBlockList02 .box .text .type {
  color: #c96a84;
  letter-spacing: 1.78px;
}

.modBlockList02 .box .text .title {
  padding: 20px 30px 0px;
  font-size: 14px;
  font-size: 1.4rem;
  text-align: left;
  font-weight: bold;
}

.modBlockList02 .box .text .message {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 2;
  padding: 10px 30px 30px;
  text-align: left;
}

.modBlockList03 {
  margin-bottom: 60px;
}

@media only screen and (max-width: 992px) {
  .modBlockList03 {
    margin-bottom: 20px;
  }
}

.modBlockList03 .box {
  background-color: #fff;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.2) 0 5px 20px;
  margin-bottom: 40px;
  padding: 140px 40px;
  text-align: left;
  max-width: 980px;
  margin-left: auto;
  margin-right: auto;
}

.modBlockList03 .box:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

@media only screen and (max-width: 992px) {
  .modBlockList03 .box {
    padding: 10px 20px;
  }
}

@media only screen and (max-width: 992px) {
  .modBlockList03 .box .inner {
    min-height: 160px;
  }
}

@media only screen and (max-width: 600px) {
  .modBlockList03 .box .inner {
    min-height: 120px;
  }
}

.modBlockList03 .box .inner .head:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

@media only screen and (max-width: 992px) {
  .modBlockList03 .box .inner .head {
    line-height: 1.4;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .modBlockList03 .box .inner .head {
    margin-bottom: 0;
  }
}

.modBlockList03 .box .inner .head .title {
  font-size: 40px;
  font-size: 4rem;
  font-style: oblique;
  font-family: "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", "メイリオ", Meiryo, serif;
  letter-spacing: 1.78px;
  margin-bottom: 30px;
  float: left;
}

@media only screen and (max-width: 992px) {
  .modBlockList03 .box .inner .head .title {
    font-size: 26px;
    font-size: 2.6rem;
    float: none;
  }
}

.modBlockList03 .box .inner .head .time {
  float: left;
  background-color: #e64398;
  color: #fff;
  display: inline;
  padding: 7px 15px;
  font-family: "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", "メイリオ", Meiryo, serif;
  font-size: 20px;
  font-size: 2rem;
  line-height: 1;
  position: relative;
  top: 12px;
  left: 20px;
}

@media only screen and (max-width: 992px) {
  .modBlockList03 .box .inner .head .time {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.modBlockList03 .box .inner .head .time span {
  font-size: 32px;
  font-size: 3.2rem;
}

@media only screen and (max-width: 992px) {
  .modBlockList03 .box .inner .head .time span {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.modBlockList03 .box .inner p {
  font-size: 16px;
  font-size: 1.6rem;
  font-family: "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", "メイリオ", Meiryo, serif;
  width: 50%;
}

@media only screen and (max-width: 992px) {
  .modBlockList03 .box .inner p {
    width: 60%;
  }
}

.modBlockList03 .box .foot {
  background: #fff;
  margin: 0 -20px -10px -20px;
  padding: 20px;
}

.modBlockList03 .box_type01 {
  background-image: url(../img/course/bgCourse01.png);
  background-size: cover;
  background-position: right top;
}

@media only screen and (max-width: 992px) {
  .modBlockList03 .box_type01 {
    background-size: 100%;
    background-repeat: no-repeat;
  }
}

.modBlockList03 .box_type02 {
  background-image: url(../img/course/bgCourse02.png);
  background-size: cover;
  background-position: left center;
}

@media only screen and (max-width: 992px) {
  .modBlockList03 .box_type02 {
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: left top;
  }
}

.modBlockList03 .box_type02 .inner {
  float: right;
  width: 55%;
}

@media only screen and (max-width: 992px) {
  .modBlockList03 .box_type02 .inner {
    float: none;
    width: 100%;
  }
}

.modBlockList03 .box_type02 .inner .head {
  text-align: right;
}

.modBlockList03 .box_type02 .inner .head .time {
  float: right;
  left: auto;
  right: auto;
}

.modBlockList03 .box_type02 .inner p {
  width: 100%;
}

.modBlockList03 .box_type03 {
  background-image: url(../img/course/bgCourse03.png);
  background-size: cover;
  background-position: right top;
}

@media only screen and (max-width: 992px) {
  .modBlockList03 .box_type03 {
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: left top;
  }
}

.modBlockList03 .box_type04 {
  background-image: url(../img/course/bgCourse04.png);
  background-size: cover;
  background-position: right top;
}

@media only screen and (max-width: 992px) {
  .modBlockList03 .box_type04 {
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: left top;
  }
}

.modBlockList04 {
  width: 100%;
  max-width: 980px;
  margin: 0 auto 60px;
}

.modBlockList04 ul {
  margin-bottom: 20px;
}

.modBlockList04 ul:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

.modBlockList04 li {
  width: 47%;
  float: left;
}

.modBlockList04 li img {
  border: 6px solid #f9f9f9;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 5px;
}

.modBlockList04 li .title {
  text-align: center;
}

.modBlockList04 li:first-child {
  margin-right: 6%;
}

.modBlockTab {
  background-image: url(../img/course/tab_bg_pink.png);
  background-repeat: repeat-x;
  background-position: center bottom;
  text-align: center;
}

@media only screen and (max-width: 992px) {
  .modBlockTab {
    background-size: 148px;
  }
}

@media only screen and (max-width: 600px) {
  .modBlockTab {
    background-size: 98.6666px;
  }
}

.modBlockTab li {
  display: inline-block;
}

@media only screen and (max-width: 992px) {
  .modBlockTab li {
    width: 253px;
  }
}

@media only screen and (max-width: 600px) {
  .modBlockTab li {
    width: 168.6666px;
  }
}

.modBlockTab li.tab01.active {
  background-image: url(../img/course/tab01_over.png);
  background-repeat: no-repeat;
  background-position: left top;
}

@media only screen and (max-width: 992px) {
  .modBlockTab li.tab01.active {
    background: transparent;
  }
}

.modBlockTab li.tab01.active a:hover img {
  opacity: 0;
}

@media only screen and (max-width: 992px) {
  .modBlockTab li.tab01.active a:hover img {
    opacity: 1;
  }
}

.modBlockTab li.tab02.active {
  background-image: url(../img/course/tab02_over.png);
  background-repeat: no-repeat;
  background-position: left top;
}

@media only screen and (max-width: 992px) {
  .modBlockTab li.tab02.active {
    background: transparent;
  }
}

.modBlockTab li.tab02.active a:hover img {
  opacity: 0;
}

@media only screen and (max-width: 992px) {
  .modBlockTab li.tab02.active a:hover img {
    opacity: 1;
  }
}

.modBlockTab li a:hover {
  opacity: 1;
}

.modBlockTab_02 {
  background-image: url(../img/course/tab_bg_black.png);
}

.messageBlock .image {
  margin-bottom: 40px;
}

.messageBlock .name {
  text-align: right;
  font-family: "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", "メイリオ", Meiryo, serif;
  font-size: 16px;
  font-size: 1.6rem;
  margin-top: 20px;
}

@media only screen and (max-width: 992px) {
  .messageBlock .name {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.optionBlock .body {
  padding-left: 60px;
  padding-right: 60px;
  font-family: "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", "メイリオ", Meiryo, serif;
  font-size: 16px;
  font-size: 1.6rem;
}

@media only screen and (max-width: 992px) {
  .optionBlock .body {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.shopListBlock .body {
  padding: 40px 40px 30px;
}

@media only screen and (max-width: 992px) {
  .shopListBlock .body {
    padding: 20px 20px 10px;
  }
}

.shopListBlock .body .address {
  font-family: "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", "メイリオ", Meiryo, serif;
  font-size: 24px;
  font-size: 2.4rem;
}

@media only screen and (max-width: 992px) {
  .shopListBlock .body .address {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.shopListBlock .body .data {
  font-family: "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", "メイリオ", Meiryo, serif;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 2;
  margin-bottom: 30px;
}

@media only screen and (max-width: 992px) {
  .shopListBlock .body .data {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 992px) and (max-width: 992px) {
  .shopListBlock .body .data {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.shopListBlock .photoList {
  max-width: 980px;
  margin: 0 auto;
}

.shopListBlock .photoList:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

.shopListBlock .photoList li {
  float: left;
  width: 31.33333%;
  margin-right: 3%;
  border: 6px solid #f9f9f9;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 5px;
}

@media only screen and (max-width: 992px) {
  .shopListBlock .photoList li {
    width: 100%;
    margin-bottom: 10px;
  }
}

.shopListBlock .photoList li:last-child {
  margin-right: 0;
}

.shopListBlock .photoList_column3 li {
  width: 33.333333%;
}

@media only screen and (max-width: 992px) {
  .shopListBlock .photoList_column3 li {
    width: 50%;
  }
}

.shopListBlock .foot {
  max-width: 980px;
  margin: 0 auto;
  padding: 0;
}

.shopListBlock .foot .map iframe {
  width: 100%;
}

@media only screen and (max-width: 992px) {
  .shopListBlock .foot .map iframe {
    height: 50%;
  }
}

.modBlockContact {
  background-image: url(../img/common/bg03.jpg);
  background-position: center top;
  padding: 0 0 80px;
}

@media only screen and (max-width: 992px) {
  .modBlockContact {
    padding: 0 0 40px;
  }
}

.modBlockContact .body {
  background-color: #000;
  padding: 75px;
  margin-top: 80px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 5px 20px;
  color: #fff;
}

@media only screen and (max-width: 992px) {
  .modBlockContact .body {
    margin-top: 40px;
    padding: 30px 15px;
  }
}

.modBlockContact .title {
  left: 50%;
  margin-left: -116px;
  margin-bottom: 30px;
  text-align: center;
}

@media only screen and (max-width: 992px) {
  .modBlockContact .title img {
    width: 150px;
  }
}

.modBlockContact .lead {
  margin-bottom: 30px;
  text-align: center;
  font-family: "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", "メイリオ", Meiryo, serif;
}

@media only screen and (max-width: 992px) {
  .modBlockContact .lead {
    margin-bottom: 20px;
  }
}

.selectBlock {
  margin-bottom: 50px;
}

.selectBlock label {
  position: relative;
}

.selectBlock label:after {
  display: block;
  content: " ";
  position: absolute;
  top: 50%;
  right: 10px;
  width: 20px;
  height: 20px;
  margin-top: -8px;
  content: "";
  font-family: FontAwesome;
  line-height: 1;
  position: absolute;
  font-size: 14px;
  font-size: 1.4rem;
  right: 15px;
  margin: auto;
  bottom: 8px;
  color: #e64398;
  pointer-events: none;
}

.selectBlock select {
  position: relative;
  outline: inherit;
  width: 40%;
  padding: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #999;
  background: #eee;
  background: -webkit-linear-gradient(top, #fff 0%, #efebe1 100%);
  background: linear-gradient(to bottom, #fff 0%, #efebe1 100%);
}

/* modTable
------------------------------------------------------------*/
.modTbl01 {
  width: 100%;
  max-width: 980px;
  margin-left: auto;
  margin-right: auto;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 2px;
}

.modTbl01 tr th {
  padding: 15px 0;
  font-size: 20px;
  font-size: 2rem;
  background-color: #c96a84;
  color: #fff;
  font-family: "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", "メイリオ", Meiryo, serif;
  text-align: center;
}

@media only screen and (max-width: 992px) {
  .modTbl01 tr th {
    padding: 10px 0;
    font-size: 17px;
    font-size: 1.7rem;
  }
}

.modTbl01 tr td {
  background-color: #fff;
  padding: 15px 20px;
  font-family: "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", "メイリオ", Meiryo, serif;
  font-size: 16px;
  font-size: 1.6rem;
}

@media only screen and (max-width: 992px) {
  .modTbl01 tr td {
    font-size: 14px;
    font-size: 1.4rem;
    padding: 10px;
  }
}

.modTbl01 tr .tit {
  background-color: #f9f1f3;
}

.modTbl01 tr .subTit {
  text-align: left;
}

.modTbl01_course01 {
  table-layout: auto;
  margin-bottom: 20px;
}

.modTbl01_course01 td:nth-child(2) {
  width: 13%;
}

.modTbl01_course01 td:nth-child(3) {
  width: 15%;
}

.modTbl01_course01 td:nth-child(4) {
  width: 25%;
}

.modTbl01_course02 {
  table-layout: auto;
}

.modTbl01_course02 td:nth-child(2) {
  width: 15%;
}

.modTbl01_course02 td:nth-child(3) {
  width: 25%;
}

/* modList
------------------------------------------------------------*/
.modListNormal {
  margin: 0 0 0 2em;
}

ul.modListNormal li:before {
  content: "\0030fb";
  margin-left: -2em;
  display: inline-block;
  *display: inline;
  *zoom: 1;
  width: 2em;
  text-align: center;
  color: #e64398;
}

ol.modListNormal li {
  list-style: decimal;
}

/* indent */
.modIndent01 li {
  text-indent: -1em;
  margin: 0 0 0 1em;
}

.modIndent02 li {
  text-indent: -2.5em;
  margin: 0 0 0 2.5em;
}

.modList01 {
  padding-left: 70px;
  width: 70%;
  font-family: "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", "メイリオ", Meiryo, serif;
}

@media only screen and (max-width: 992px) {
  .modList01 {
    padding-left: 0;
    width: 100%;
  }
}

.modList01 li {
  margin-bottom: 60px;
  font-size: 16px;
  font-size: 1.6rem;
  text-align: left;
}

.modList01 li:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

@media only screen and (max-width: 992px) {
  .modList01 li {
    margin-bottom: 20px;
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.modList01 li .tit {
  width: 20%;
  float: left;
}

@media only screen and (max-width: 600px) {
  .modList01 li .tit {
    width: 100%;
    float: none;
    display: block;
  }
}

.modList01 li .txt {
  width: 80%;
  float: left;
}

@media only screen and (max-width: 600px) {
  .modList01 li .txt {
    width: 100%;
    float: none;
  }
}

.modList02 {
  font-family: "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", "メイリオ", Meiryo, serif;
}

.modList02 dt {
  color: #000;
  font-size: 28px;
  font-size: 2.8rem;
  position: relative;
  padding-left: 40px;
  margin-bottom: 15px;
  line-height: 1.4;
}

@media only screen and (max-width: 992px) {
  .modList02 dt {
    font-size: 23px;
    font-size: 2.3rem;
  }
}

.modList02 dt:before {
  content: "";
  display: block;
  width: 54px;
  height: 49px;
  background-image: url(../img/common/decoSubTitle.png);
  background-repeat: no-repeat;
  background-position: left center;
  position: absolute;
  left: -25px;
  top: -10px;
  margin-top: -2px;
}

@media only screen and (max-width: 992px) {
  .modList02 dt:before {
    left: -20px;
    top: 0;
    background-size: 100%;
  }
}

.modList02 dd {
  padding: 0 30px;
  font-size: 16px;
  font-size: 1.6rem;
  margin-bottom: 50px;
}

@media only screen and (max-width: 992px) {
  .modList02 dd {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.modList02 dd:last-child {
  margin-bottom: 0;
}

/* フォーム
***************************************/
.modDlForm {
  margin: 0 0 36px;
}

.modDlForm dt {
  float: left;
  clear: both;
  width: 16em;
  padding: 33px 0 20px 63px;
  position: relative;
}

@media only screen and (max-width: 992px) {
  .modDlForm dt {
    float: none;
    width: auto;
    padding: 33px 0 0 63px;
  }
}

.modDlForm dt.required:before, .modDlForm dt.any:before {
  position: absolute;
  left: 0;
  top: 33px;
  color: #FFF;
  font-size: 11px;
  font-size: 1.1rem;
  padding: 2px 10px;
}

.modDlForm dt.required:before {
  content: "必須";
  background: #e4007f;
}

.modDlForm dt.any:before {
  background: #999999;
  content: "任意";
}

.modDlForm dd {
  border-bottom: solid 1px #ccc;
  padding: 33px 0;
  padding-left: 17em;
}

@media only screen and (max-width: 992px) {
  .modDlForm dd {
    padding: 10px 0;
  }
}

.modDlForm dd .parts {
  padding: 10px 0;
}

.modDlForm dd .parts textarea,
.modDlForm dd .parts input[type="text"],
.modDlForm dd .parts input[type="email"] {
  width: 100%;
}

.modDlForm dd .parts.radio_inline > div, .modDlForm dd .parts.check_inline > div {
  display: inline-block;
}

.modDlForm dd .parts.name {
  display: flex;
  justify-content: space-between;
}

.modDlForm dd .parts.name > div {
  width: 48.5%;
}

.modDlForm dd .parts.post, .modDlForm dd .parts.tel {
  display: flex;
}

.modDlForm dd .parts.post .hyphen, .modDlForm dd .parts.tel .hyphen {
  padding: 10px 10px 0 10px;
}

@media only screen and (max-width: 600px) {
  .modDlForm dd .parts.post .hyphen, .modDlForm dd .parts.tel .hyphen {
    padding: 10px 5px 0 5px;
  }
}

.modDlForm dd .parts.password input {
  width: 100%;
  max-width: 300px;
}

@media only screen and (max-width: 600px) {
  .modDlForm dd .parts.password input {
    max-width: none;
  }
}

.modDlForm dd .parts.password .text {
  padding: 10px 0;
}

.modDlForm .validationError:before {
  content: "\f071";
  font-family: FontAwesome;
  line-height: 1;
  color: #e4007f;
  margin-right: 6px;
}

/* other
------------------------------------------------------------*/
/*↓消さないこと*/
.modLoading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
}

.modLoading i {
  font-size: 24px;
  font-size: 2.4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -0.5em;
  margin-top: -0.5em;
}

/*↑消さないこと*/
.modCaption {
  font-family: "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", "メイリオ", Meiryo, serif;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 2;
  color: #000;
  margin-bottom: 70px;
}

@media only screen and (max-width: 992px) {
  .modCaption {
    text-align: left;
  }
  .modCaption br {
    display: none;
  }
}

.modAttention {
  font-family: "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", "メイリオ", Meiryo, serif;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 2;
}

@media only screen and (max-width: 992px) {
  .modAttention {
    text-align: left;
  }
  .modAttention br {
    display: none;
  }
}

.modAttention_white {
  color: #fff;
}

#first .modAttention {
  margin-bottom: 70px;
}

.modLine01 {
  margin-bottom: 20px;
}

.modBox01 {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 980px;
}

.modBox01 .deco {
  position: absolute;
}

@media only screen and (max-width: 992px) {
  .modBox01 .deco {
    width: 30%;
  }
}

.modBox01 .decoLT {
  left: -20px;
  top: -20px;
}

@media only screen and (max-width: 992px) {
  .modBox01 .decoLT {
    left: -15px;
    top: -15px;
  }
}

.modBox01 .decoLB {
  left: -20px;
  bottom: -20px;
}

@media only screen and (max-width: 992px) {
  .modBox01 .decoLB {
    left: -15px;
    bottom: -15px;
  }
}

.modBox01 .decoRT {
  right: -20px;
  top: -20px;
}

@media only screen and (max-width: 992px) {
  .modBox01 .decoRT {
    right: -15px;
    top: -15px;
  }
}

.modBox01 .decoRB {
  right: -20px;
  bottom: -20px;
}

@media only screen and (max-width: 992px) {
  .modBox01 .decoRB {
    right: -15px;
    bottom: -15px;
  }
}
