@charset "utf-8";

/* modBlock
------------------------------------------------------------*/
#modAnkerBlock {
    .list {
        @include fz(16);
        display: flex;
        justify-content: center;
        margin-left:-0.5%;
        margin-bottom: 80px;
        @include tabletP{
            display: block;
            margin-bottom: 30px;
            @include fz(14);
        }
        li {
            width: 23%;
            margin-right: 1%;
            display: table;
            @include tabletP{
                display: block;
                width: auto;
                margin-bottom: 5px;
                br{
                    display: none;
                }
            }
            a {
                display: table-cell;
                vertical-align: middle;
                padding: 17px 0 25px;
                border:1px solid $baseSubColor;
                border-radius: 4px;
                line-height: 1.4;
                height: 80px;
                text-align: center;
                color:$baseColor;
                font-family: $fontMincho;
                position: relative;
                @include tabletP{
                    text-align: left;
                    height:auto;
                    display: block;
                    padding: 4% 8% 4% 4%;
                }
                &:hover{
                    background-color: $mainColor02;
                    border:1px solid $mainColor;
                    text-decoration: none;
                }
                &:after{
                    content:$fa-chevron-down;
                    @include iconFont();
                    position: absolute;
                    @include fz(14);
                    left:0;
                    right:0;
                    margin: auto;
                    bottom:8px;
                    color:$mainColor;
                    @include tabletP{
                        left:auto;
                        right:10px;
                        top:50%;
                        bottom:auto;
                        margin-top:-0.5em;
                    }
                }
            }
        }
    }
}
.secBlock{
    background-image: url(../img/common/bg02.jpg);
    background-size: cover;
    background-position: center bottom;
    padding: 60px 0;
    text-align: center;
    @include tabletP{
        padding: 30px 10px;
    }
    &_type01{
        background-image: url(../img/common/bg01.png);
    }
    &_type02{
        background-image: url(../img/common/bg03.jpg);
    }
    &_type03{
        background-image: url(../img/common/bg01.jpg);
        background-repeat: repeat;
        background-size: auto;
    }
    &_type04{
        background-image: url(../img/common/bg02.png);
    }
    &_type05{
        background: transparent;
        background-color: #f8f4f4;
    }
    &_type06{
        background-image: url(../img/common/bg03_2.jpg);
    }
				&_type07{
					background-image: url(../img/common/bg05.png);
					background-repeat: repeat;
					background-size: auto;

				}
				&_type08{
					background-image: url(../img/common/bg06.png);
					background-repeat: repeat;
					background-size: auto;

				}
    &_flash{
        position: relative;
        &:before{
            content:"";
            background-image: url(../img/common/bgFlash.png);
            background-size: cover;
            background-position: center top;
            display: block;
            position: absolute;
            top:0;
            bottom:0;
            left:0;
            right:0;
        }
    }
    &_typeShop01{
        background-image: url(../img/shop/bgShop01.png);
    }
    &_typeShop02{
        background-image: url(../img/shop/bgShop02.png);
    }
    &_typeShop03{
        background-image: url(../img/shop/bgShop03.png);
    }
    .container{
        position: relative;
        z-index:1;
    }
    .body{
        text-align: left;
        background-color: #fff;
        max-width: 980px;
        margin:0 auto;
        padding: 60px 20px;
        box-shadow: rgba(0,0,0,0.2) 0 5px 20px;
        color:#000;

        &_type01{
            background-image: url(../img/company/bg01.png);
            background-size: cover;
            background-position: center top;
            @include tabletP{
                background-size: 100%;
                background-repeat: no-repeat;
                background-position: right top;
            }
        }
        &_alpha{
            background-color: rgba(255,255,255,0.9);
        }
        @include tabletP{
            padding:30px 15px;
        }
    }
}
.modBlockList01{
    .box{
        background-color: #fff;
        position: relative;
        box-shadow: rgba(0,0,0,0.2) 0 5px 20px;
        margin-bottom: 40px;
        padding: 40px 40px 40px 70px;
        text-align: left;
        max-width: 980px;
        margin-left: auto;
        margin-right: auto;
        @include clearfix();
        @include tabletP{
            padding: 40px;
        }
        &:before{
            content:"";
            border:1px solid #c29c9c;
            display: block;
            position: absolute;
            top:10px;
            bottom:10px;
            left:10px;
            right: 10px;
        }
        .text{
            float: left;
            width: 64%;
            @include tabletP{
                float: none;
                width: 100%;
                br{
                    display: none;
                }
            }
            .step{
                margin-bottom: 15px;
                @include tabletP{
                    margin-bottom: 10px;
                    img{
                        max-width: 80px;
                    }
                }
            }
            .title{
                @include fz(28);
                font-family: $fontMincho;
                letter-spacing: 1.78px;
                line-height: 1.4;
                color:#000;
                margin-bottom: 10px;
                @include tabletP{
                    @include fz(23);
                }
            }
            p{
                @include fz(16);
                font-family: $fontMincho;
                @include tabletP{
                    @include fz(14);
                }
            }

            sup{
                font-size: 70%;
            }

            .caution{
                font-size: 87%;
            }
        }
        .image{
            float: right;
            width: 36%;
            text-align: center;
            @include tabletP{
                float: none;
                width: 100%;

            }
        }
    }
    &_type02{
        .box{
            padding: 50px 40px 50px 70px;
            @include tabletP{
                padding: 40px;
            }
            .text{
                width: 62%;
                @include tabletP{
                     width: 100%;

                }
                .title{
                    color:$mainColor03;
                    span{
                        @include fz(22);
                        letter-spacing: 1px;
                        @include tabletP{
                            @include fz(20);
                        }
                    }
                }
            }
            .image{
                text-align: right;
                @include tabletP{
                    margin-top: 20px;
                 text-align: center;
                }
            }

        }
    }

}

.modBlockList02{
    @include clearfix();
    max-width: 980px;
    margin: 0 auto;
    .box {
        background-color: #fff;
        box-shadow: rgba(0,0,0,0.2) 0 5px 20px;
        width: 30%;
        margin: 0 1.6666% 40px;
        float: left;
        @include tabletP {
            width: 47.5%;
            margin: 0 1.25% 20px;
        }
        @include sphoneP {
            width: 100%;
            margin: 0 0 20px;
        }
        .image {
            position: relative;
            @include tabletP {

            }
            img {
                width: 100%;
                height:auto;
            }
            .shop {
                background-color: $mainColor;
                color:#fff;
                padding: 0px 10px;
                @include fz(14);
                font-family: $fontMincho;
                position: absolute;
                bottom:10px;
                left:10px;
            }
        }


        .text {
            font-family: $fontMincho;
            .name {
                margin-top: 12px;
                @include fz(24);
                @include tabletP{
                    @include fz(20);
                }
            }
            .type {
                color:$mainColor03;
                letter-spacing: 1.78px;
            }
            .title{
                padding: 20px 30px 0px;
                @include fz(14);
                text-align: left;
                font-weight: bold;
            }
            .message {
                @include fz(14);
                line-height: 2;
                padding: 10px 30px 30px;
                text-align: left;

            }
        }

    }
}

.modBlockList03{
    margin-bottom: 60px;
    @include tabletP {
        margin-bottom: 20px;
    }
    .imageBox{

    }
    .box{
        background-color: #fff;
        position: relative;
        box-shadow: rgba(0,0,0,0.2) 0 5px 20px;
        margin-bottom: 40px;
        padding: 140px 40px;
        text-align: left;
        @include clearfix();
        max-width: 980px;
        margin-left: auto;
        margin-right: auto;
        @include tabletP{
            padding: 10px 20px;
        }
        .inner{
            @include tabletP{
                min-height: 160px;
            }
            @include sphoneP{
                min-height: 120px;
            }
            .head{
                @include clearfix();
                @include tabletP{
                    line-height: 1.4;
                    margin-bottom: 20px;
                }
                @include sphoneP{
                    margin-bottom: 0;

                }
                .title{
                    @include fz(40);
                    font-style:oblique;
                    font-family: $fontMincho;
                    letter-spacing: 1.78px;
                    margin-bottom: 30px;
                    float: left;
                    @include tabletP{
                        @include fz(26);
                        float:none;
                    }
                }
                .time{
                    float: left;
                    background-color: $mainColor;
                    color:#fff;
                    display: inline;
                    padding: 7px 15px;
                    font-family: $fontMincho;
                    @include fz(20);
                    line-height: 1;
                    position: relative;
                    top:12px;
                    left:20px;
                    @include tabletP{
                        @include fz(12);
                    }
                    span{
                        @include fz(32);
                        @include tabletP{
                            @include fz(18);
                        }
                    }
                }
            }
            p{
                @include fz(16);
                font-family: $fontMincho;
                width: 50%;
                @include tabletP{
                    width: 60%;
                }
            }

        }
        .foot{
            background: #fff;
            margin: 0 -20px -10px -20px;
            padding: 20px;
        }
        &_type01{
            background-image: url(../img/course/bgCourse01.png);
            background-size: cover;
            background-position: right top;
            @include tabletP{
                background-size: 100%;
                background-repeat: no-repeat;
            }
        }
        &_type02{
            background-image: url(../img/course/bgCourse02.png);
            background-size: cover;
            background-position: left center;
            @include tabletP{
                background-size: 100%;
                background-repeat: no-repeat;
                background-position: left top;
            }

            .inner{
                float: right;
                width: 55%;
                @include tabletP{
                    float: none;
                    width: 100%;
                }
                .head{
                    text-align: right;
                    .time{
                        float: right;
                        left: auto;
                        right: auto;
                    }
                }
                p{
                    width: 100%;
                }
            }
        }
        &_type03{
            background-image: url(../img/course/bgCourse03.png);
            background-size: cover;
            background-position: right top;
            @include tabletP{
                background-size: 100%;
                background-repeat: no-repeat;
                background-position: left top;
            }
        }
        &_type04{
            background-image: url(../img/course/bgCourse04.png);
            background-size: cover;
            background-position: right top;
            @include tabletP{
                background-size: 100%;
                background-repeat: no-repeat;
                background-position: left top;
            }
        }


    }

}

.modBlockList04{
	width: 100%;
	max-width: 980px;
	margin: 0 auto 60px;
	ul{
		@include clearfix();
		margin-bottom: 20px;
	}
	li{
		width: 47%;
		float: left;
		img{
			border: 6px solid #f9f9f9;
			box-shadow: rgba(0, 0, 0, 0.2) 0 2px 5px;

		}
		.title{
			text-align: center;
		}
		&:first-child{
			margin-right: 6%;
		}
	}
}

.modBlockTab{
	background-image: url(../img/course/tab_bg_pink.png);
	background-repeat: repeat-x;
	background-position: center bottom;
	@include tabletP {
		background-size: 148px;
	}
	@include sphoneP {
		background-size: 98.6666px;
	}
		text-align: center;
		li{
			display: inline-block;
			@include tabletP {
				width: 253px;
			}
			@include sphoneP {
				width: 168.6666px;
			}
			&.tab01.active{
				background-image: url(../img/course/tab01_over.png);
				background-repeat: no-repeat;
				background-position: left top;
				@include tabletP {
					background: transparent;
				}
				a:hover img{
					opacity: 0;
					@include tabletP {
						opacity: 1;
					}
				}
			}
			&.tab02.active{
				background-image: url(../img/course/tab02_over.png);
				background-repeat: no-repeat;
				background-position: left top;
				@include tabletP {
					background: transparent;
				}
				a:hover img{
						opacity: 0;
					@include tabletP {
						opacity: 1;
					}
				}
			}
			a{
				&:hover{
					opacity: 1;
				}
			}
		}

	&_02{
		background-image: url(../img/course/tab_bg_black.png);
	}
}



.messageBlock{
    .image{
        margin-bottom: 40px;
    }
    .name{
        text-align: right;
        font-family: $fontMincho;
        @include fz(16);
        margin-top: 20px;
        @include tabletP{
            @include fz(14);
        }
    }
}
.optionBlock{
    .body{
        padding-left: 60px;
        padding-right: 60px;
        font-family: $fontMincho;
        @include fz(16);
        @include tabletP{
            padding-left: 30px;
            padding-right: 30px;
        }
    }
}
.shopListBlock{
    .body{
        padding: 40px 40px 30px;
        @include tabletP {
            padding: 20px 20px 10px;
        }
        .address{
            font-family: $fontMincho;
            @include fz(24);
            @include tabletP {
                @include fz(18);
            }
        }
        .data{
            font-family: $fontMincho;
            @include fz(16);
            line-height: 2;
            margin-bottom: 30px;
            @include tabletP {
                margin-bottom: 15px;
                @include tabletP{
                    @include fz(14);
                }
            }
        }

    }

    .photoList{
        max-width: 980px;
        margin: 0 auto;
        @include clearfix();
        li{
            float: left;
            width: 31.33333%;
												margin-right: 3%;
												border:6px solid #f9f9f9;
												box-shadow: rgba(0, 0, 0, 0.2) 0 2px 5px;

            @include tabletP {
                width: 100%;
													margin-bottom: 10px;
            }
									&:last-child{
										margin-right: 0;
									}
        }
        &_column3{
            li{
                width: 33.333333%;
                @include tabletP {
                    width: 50%;
                }
            }
        }
    }
			.foot{
				max-width: 980px;
				margin: 0 auto;
				padding: 0;
				.map{
					iframe{
						width: 100%;
						@include tabletP {
							height:50%;
						}
					}
				}
			}

}
.modBlockContact{
	background-image: url(../img/common/bg03.jpg);
	background-position: center top;
	padding: 0 0 80px;
	@include tabletP{
		padding: 0 0 40px;
	}

	.body{
		background-color: #000;
		padding: 75px;
		margin-top: 80px;
		box-shadow: rgba(0,0,0,0.2) 0 5px 20px;
		color:#fff;
		@include tabletP{
			margin-top: 40px;
			padding:30px 15px;
		}
	}

	.title{
        left: 50%;
        margin-left: -116px;
		margin-bottom: 30px;
		text-align: center;
		@include tabletP{
			img{
				width: 150px;
			}
		}
	}

	.lead{
		margin-bottom: 30px;
		text-align: center;
		font-family: $fontMincho;
		@include tabletP{
			margin-bottom: 20px;
		}
	}
}
.selectBlock{
    margin-bottom: 50px;
    label{
        position: relative;
    }
    label:after {
        display: block;
        content: " ";
        position: absolute;
        top: 50%;
        right: 10px;
        width: 20px;
        height: 20px;
        margin-top: -8px;
        content: "";
        font-family: FontAwesome;
        line-height: 1;
        position: absolute;
        font-size: 14px;
        font-size: 1.4rem;
        right: 15px;
        margin: auto;
        bottom: 8px;
        color: #e64398;
        pointer-events: none;
    }
    select{
        position: relative;
        outline: inherit;
        width: 40%;
        padding: 10px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 1px solid #999;
        background: #eee;
        background: -webkit-linear-gradient(top, #fff 0%,#efebe1 100%);
        background: linear-gradient(to bottom, #fff 0%,#efebe1 100%);
    }
}
