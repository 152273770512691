@charset "utf-8";

/* Reset
------------------------------------------------------------*/
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td
{
	margin: 0;
	padding: 0;
}
address,
em
{
	font-style: normal;
}
strong,
th
{
	font-weight: normal;
}
table
{
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
}
th
{
	text-align: left;
}
hr,
legend
{
	display: none;
}
h1,
h2,
h3,
h4,
h5,
h6
{
	font-size: 100%;
	font-weight: normal;
}
img,
fieldset
{
	border: 0;
}
img
{
	border:0;
	vertical-align:top;
	max-width:100%;
	height:auto;
}

li
{
	list-style-type: none;
}
input[type="submit"],button,label,select{
	cursor:pointer;
}

input[type="submit"],button{
	&:hover{
		
	}
}

input[type="text"],input[type="tel"],input[type="email"],input[type="password"],input[type="button"],textarea,input[type="submit"],input[type="password"],button,label{
	-webkit-appearance: none;
	border-radius:0;
}

.clearfix{
	@include clearfix;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* link
------------------------------------------------------------*/
a { 
	color: $baseLinkColor; text-decoration: none;
	
	&:hover {
		text-decoration: underline; color: $baseLinkColor; opacity:0.8;
	}
}

button:hover{
	opacity:0.8;
}

/* Fonts
------------------------------------------------------------*/
html{ font-size: 62.5%; }/* 10px base */

body{
	-webkit-text-size-adjust: 100%;
	word-wrap : break-word;
	overflow-wrap : break-word; 
}
body>div,input,button,textarea,select
{
	@include fz;
	font-family:$baseFontFamily;
	line-height:$baseLineHeight;
	color: $baseColor;
	@include tabletP{
		@include fz(12);
	}
}

/* form
------------------------------------------------------------*/
select{
	padding:10px;
	border-radius:5px;
}

textarea,
input[type="text"],
input[type="password"],
input[type="email"],
select{
	padding:10px;
	border-radius:5px;
	border:solid 1px #aaa;
}

textarea.error,
input[type="text"].error,
input[type="password"].error,
input[type="email"].error,
select.error{
	background-color:rgba(255,242,242,1.00);
	border:solid 2px $colorStrong;
}



/* radio
-------------*/
input[type="radio"] {
	display: none;
}

input[type="radio"] + label {
	position: relative;
	display: inline-block;
	padding: 3px 10px 3px 24px;
	cursor: pointer;
	width:100%;
}

input[type="radio"] + label::before,
input[type="radio"] + label::after {
	content: '';
	top: 50%;
	position: absolute;
	border-radius: 100%;
	transition: all .2s;
}

input[type="radio"] + label::before {
	width: 16px;
	height: 16px;
	margin-top: -9px;
	left: 0;
	background: #EEE;
	border: 1px solid #ccc;
}
input[type="radio"].error + label::before{
	border: 1px solid $colorStrong;
	background-color:rgba(255,242,242,1.00);
}
input[type="radio"] + label:hover::before {
	background: #FEFEFE;
}
input[type="radio"] + label::after {
	opacity: 0;
	left: 3px;
	width: 10px;
	height: 10px;
	margin-top: -6px;
	background: $colorBlue;
	transform: scale(0.5);
}
input[type="radio"]:checked + label::before {
	background: #fff;
	border: 1px solid $colorBlue;
}
input[type="radio"]:checked + label::after {
	opacity: 1;
	transform: scale(1);
}


/* checkbox
-------------*/
input[type="checkbox"] {
	display: none;
}
input[type="checkbox"] + label {
	position: relative;
	display: inline-block;
	padding: 3px 10px 3px 22px;
	cursor: pointer;
	-webkit-transition: all .2s;
	transition: all .2s;
}
input[type="checkbox"] + label::before,
input[type="checkbox"] + label::after {
	position: absolute;
	content: '';
}
input[type="checkbox"] + label::before {
	top: 50%;
	left: 0;
	width: 14px;
	height: 14px;
	margin-top: -8px;
	background: #f4f4f4;
	border: 1px solid #ccc;
	border-radius: 3px;
}
input[type="checkbox"] + label::after {
	opacity: 0;
	top: 50%;
	left: 3px;
	width: 8px;
	height: 4px;
	margin-top: -4px;
	border-left: 2px solid $colorBlue;
	border-bottom: 2px solid $colorBlue;
	transform: rotate(-45deg) scale(0.5);
}
input[type="checkbox"] + label:hover::before {
	background: #fff;
}
input[type="checkbox"]:checked + label::before {
	background: #fff;
	border: 1px solid $colorBlue;
}
input[type="checkbox"]:checked + label::after {
	opacity: 1;
	transform: rotate(-45deg) scale(1);
}


